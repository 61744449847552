@import '~leaflet/dist/leaflet.css';
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    color: #242424;
    line-height: 1.7em;
}

.site-width-container {
    max-width: 1079px !important;
    margin: 0 auto !important;
    outline: none !important;
    position: relative !important;
}

.site-width-container .title {
    margin-bottom: 0px;
    margin-top: 30px;
}

.site-width-container .small-title {
    margin-top: 0px;
    margin-bottom: 20px;
    color: #575757 !important;
}

:host {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'poppins';
    src: url('../src/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'poppins_thin';
    src: url('../src/fonts/Poppins-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'poppins_medium';
    src: url('../src/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'poppins_semibold';
    src: url('../src/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'poppins_bold';
    src: url('../src/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Franz Sans - Bold';
    src: url('../src/fonts/FranzSans-Bold.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

/* common css */
.ml-10 {
    margin-left: 10px;
}

.secondary-button {
    background-color: #f57d55 !important;
    color: #fff !important;
    font-family: 'Poppins';
}

.ht-button {
    color: #fff !important;
    border-width: 1px !important;
    border-radius: 0px;
    font-size: 14px;
    line-height: 1.7em !important;
    background-color: transparent;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid;
    padding: 10px 40px;
    text-decoration: none;
    font-weight: 600;
    /* font-weight: bold; */
    /* margin-left: 15px; */
}

.fSearch {
    background-color: rgba(255, 255, 255, 1) !important;
    border-color: rgba(239, 121, 80, 1) !important;
    color: #00797A !important;
    min-width: 215px !important;
}

.ht-button:hover {
    background-color: rgba(0, 0, 0, .05) !important;
    transition: all 300ms ease 0ms;
    border: 1px solid transparent !important;
}

.sr-button {
    margin-left: 15px;
}

.card-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: calc(50% - 12px);

}

.card-column-33 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: calc(33.33% - 12px);

    @media screen and (max-width: 1365px) {
        width: calc(100% - 0px);
        margin-bottom: 15px;
    }
}

.grey-container {
    background-color: rgba(112, 112, 112, 0.13) !important;
    padding: 25px 20px;
}

.white-container {
    background-color: #fff !important;
    padding: 4%;
}

.cust-card {
    padding: 40px !important;
}

.hmt-row {
    display: flex;
    align-items: stretch;
    width: calc(100% + 40px);
    margin: 0 -20px;
}

.hmt-row .hmt-col-half {
    width: calc(50% - 40px);
    vertical-align: top;
    display: inline-block;
    padding: 0 20px;
}

.hmt-row .hmt-col-half .card-img {
    /* width: 100%; */
    max-width: 100%;
    height: auto;
    max-height: 250px;
    min-width: 200px;
    float: right;
}

.hmt-row .hmt-col-full {
    width: 100%;
    vertical-align: top;
}

.mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
    margin-top: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding-bottom: 10px;
    margin-top: 0 !important;
    letter-spacing: -1px;
}

h1 {
    font-size: 52px;
    /* color: #11817e; */
    margin-bottom: 20px !important;
    line-height: 1em;
}

h2 {
    font-size: 32px;
    color: #11817e;
    line-height: 1em;
    margin-bottom: 10px;
    word-wrap: break-word;
}

h3 {
    font-size: 28px;
    /* color: #11817e; */
}

label {
    color: #242424;
    font-size: 16px;
    font-weight: 600;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

.back-link {
    height: 36px;
    margin: 18px 0px;
    display: block;
}

.card-heading {
    margin-bottom: 20px;
    // margin-left: 5px;

    a {
        cursor: pointer !important;
        font-size: 12px;
        font-family: 'Poppins';
        color: #000000;
        text-decoration: none;

    }

    @media (max-width: 1024px) {
        margin: 0 20px !important;
    }

}

.default-image {
    background: url(./assets/images/default-image.png);
    bottom: -62px;
    top: auto;
    left: 0px;
    right: auto;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    margin-bottom: -20px;
}

.date-container {
    margin: 20px 0px;
}

.button {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
    border-radius: 6px;
    border: none;
    font-weight: bold;
    padding: 8px;
    margin-left: 8px;
}

a.links {
    font-size: 16px;
    color: #EF7950;
    word-break: break-all;
    line-height: 1.7em;
    font-family: 'poppins';
    font-weight: 500;
}

#how-to-guide a {
    font-family: "poppins_medium"
}

/*media queries*/
@media screen and (max-width: 768px) {
    .hmt-row .hmt-col-half .card-img {
        width: 100% !important;
        min-width: auto !important;
    }
}

@media screen and (max-width: 480px) {
    h1 {
        font-size: 37px;
        line-height: 1.1em;
    }

    h2 {
        font-size: 26px;
        line-height: 1em;
        word-wrap: break-word;
    }

    .hmt-col-half {
        width: calc(100% - 38px) !important;
    }

    .mobile-hidden {
        display: none !important;
    }
}

.SSBold_Heading {
    font-family: 'Franz Sans - Bold';
    font-size: 52px;
    color: #11817e;
}

.SSBold_Heading_small {
    font-family: 'Franz Sans - Bold';
    font-size: 32px;
}

.ql-editor {
    font-family: 'Poppins', sans-serif;
    padding: 0px;
}

.ql-editor p {
    margin-top: 0px !important;
}

.ql-clipboard {
    display: none !important;
}

.mat-chip {
    background: #d0e4ec !important;
    color: #6e7a8a;
    cursor: pointer;
    font-family: 'Poppins';
    width: 230px;
    padding: 10px;
    background: #fff;
    border-radius: 4px !important;
    box-shadow: inset 0 -1px 0 #ccd4db;

    @media (max-width: 767px) {
        width: 100%;
    }
}

.custom-chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    justify-content: left;

    @media (max-width: 950px) {
        gap: 10px;
    }
}

.btn-for-search {
    border-color: #f57d55 !important;
    background: #f57d55 !important;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: #fff;
    height: 48px;
    margin-left: -9.6px !important;
    margin-top: 0.2px;
    z-index: 100;
    padding: 11.5px;
    border: 1px solid #fe7c4a;
}

button.active {
    cursor: pointer !important;
    background: #fc7844;
}

.main-location {
    align-self: flex-start;
    margin-top: -38px;
    margin-left: 10px;
    // max-width: 288px;

    img {
        height: 20px;
        margin-top: 10px;
        margin-right: 5px;
    }

    button {
        margin-top: 18px;
        padding: 10px;
        height: 46px !important;
        width: max-content;

        @media (max-width: 1000px) {
            width: auto !important;
        }
    }

    @media (max-width: 1000px) {
        margin: 20px 0 !important;
    }

}

.main-location-view {
    margin-top: 0px;

    img {
        height: 20px;
        margin-top: 10px;
        margin-right: 5px;
    }

}

h3.main-title {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 24px !important;
}

h2.main-title {
    margin-bottom: 20px !important;
    margin-top: 10px !important;
    padding-bottom: 10px !important;

}

.mt15 {
    margin-top: 15px;
}

.mt20 {
    margin-bottom: 20px !important;
}

.mt-20 {
    margin-top: 20px;
}

.secondary-color {
    color: #EF7950 !important;
}

.left-chart-titles {
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
    padding: 0px;
    color: #11817e;
}

.mat-mdc-card-content {
    padding: 0px !important;
}

.padding-16 {
    padding: 16px;
}

.primary {
    color: #00797A !important;
    font-weight: 500;
}

.container-funding {
    display: flex;
    padding: 35px 20px 0px;
    gap: 12px;
}

.li-card {
    padding: 10px 16px;
    font-size: 16px !important;
}

.mb20 {
    margin-bottom: 30px !important;
}

.ngx-quill-view ol li,
ul li {
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    color: rgba(0, 0, 0, .87) !important
}

.ql-toolbar .ql-formats:nth-child(2),
.ql-toolbar .ql-formats:nth-child(3),
.ql-toolbar .ql-formats:nth-child(5),
.ql-toolbar .ql-formats:nth-child(6),
.ql-toolbar .ql-formats:nth-child(7),
.ql-toolbar .ql-formats:nth-child(8),
.ql-toolbar .ql-formats:nth-child(9),
.ql-toolbar .ql-formats:nth-child(10),
.ql-toolbar .ql-formats:nth-child(11),
.ql-toolbar .ql-formats:nth-child(12),
.ql-toolbar .ql-formats:nth-child(13) {
    display: none !important;
}

button.ql-image,
.ql-video {
  display: none !important;
}

.label-hr {
    display: flex !important;
    align-items: center !important;
    text-align: left !important;
    margin: 15px 0PX 25px !important;
}

.label-hr span {
    font-size: 1.3em;
    font-weight: 100;
    padding-right: 8px;
    color: #11817e;
}

.label-hr:before {
    content: "";
    width: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, .24);
}

.label-hr:after {
    margin-left: 0.25em;
}

.label-hr:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, .24);
}

.ql-container.ql-snow {
    padding: 8px;
    margin-bottom: 5px;
}

.quill-error {
    margin-top: -10px;
    font-size: 12px;
    color: #f44336;
    font-family: "Roboto", sans-serif;
}

.p-15 {
    padding: 10px 15px;
}

.mat-drawer-container {
    background-color: transparent !important;
}

.mat-mdc-text-field-wrapper {
    background-color: rgba(232, 239, 239, 1);
}

.ql-toolbar.ql-snow {
    background-color: rgba(232, 239, 239, 1);
}

#browse_button{
    padding: 20px;
    background-color: rgb(232, 239, 239);
}

.ql-editor.ql-blank::before {
    position: absolute;
    left: 22px !important;
  }

.mat-drawer .mat-drawer-inner-container {
    overflow: hidden !important;
}
.mat-mdc-card-sm-image {
    width: auto !important;
    height: auto !important;
}